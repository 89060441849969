@teams1: teams1;
.@{teams1}-wrapper {
  min-height: 446px;
  overflow: hidden;
  background-image: url('../../images/contact-bg.png');
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  .@{teams1} {
    overflow: hidden;
    height: 100%;
    padding: 64px 24px;
    > .title-wrapper {
      margin: 48px auto 48px;

      h1 {
        font-size: 38px;
        font-family: 'STKaiTi';
        color: rgb(185, 153, 115);
        line-height: 0.741;
        -moz-transform: matrix(1.27025797256316, 0, 0, 1.27025797256316, 0, 0);
        -webkit-transform: matrix(1.27025797256316, 0, 0, 1.27025797256316, 0, 0);
        -ms-transform: matrix(1.27025797256316, 0, 0, 1.27025797256316, 0, 0);
      }

    }
    > .comp-wrapper {
      text-align: center;
      margin-bottom: 24px;

      .comp {
        margin: auto 20px;
        font-size: 24px;
        font-family: 'STKaiTi';
        color: rgb(185, 153, 115);
        display: inline-block;
      }
    }
    .block-wrapper {
      position: relative;
      height: 100%;
      overflow: hidden;
      padding: 20px 0;
      .block {
        display: inline-block;
        text-align: center;
        // margin-bottom: 48px;
        &.queue-anim-leaving {
          position: relative !important;
        }
      }
    }
    &-image,
    &-title,
    &-text,
    &-content {
      width: 200px;
      margin: auto;
      line-height: 1.5;
    }
    &-image {
      color: #404040;
      img {
        width: 100%;
      }
    }
    &-title {
      font-size: 24px;
      font-family: 'SimSun';
      color: rgb(174, 133, 74);
      line-height: 3.429;
      margin: 24px auto 8px;
    }
    &-title:after {
      content: '';
      display: block;
      opacity: 0.212;
      width: 100%;
      border-bottom: 2px solid rgb(150, 135, 157);
    }
    &-text {
      margin: 8px auto;
    }
    .teams1-text {
      font-size: 18px;
      font-family: 'FZLTHJW';
      color: rgb(185, 153, 115);
      line-height: 1.529;
    }
    &-content {
      font-size: 12px;
      color: rgb(185, 153, 115);
    }
  }
}

@media screen and (max-width: 767px) {
  .@{teams1}-wrapper {
    min-height: 1440px;

    .@{teams1} {
      > .title-wrapper {
        h1 {
          font-size: 6vw;
        }
      }
      > .comp-wrapper {

      .comp {
        font-size: 4vw;
      }
    }
    }
  }
}
